<template>
  <div class="statements-title">Adjust Bills</div>
  <a-card style="margin:0 24px;">
    <a-form :model="form" layout="vertical" ref="formRef">
      <a-row :gutter="[20, 20]">
        <a-col :span="8">
          <a-form-item field="searchVal" label="What are you looking for?">
            <a-input v-model="form.searchVal" placeholder="Enter Tracking/Order ID to search." />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-config-provider :locale="enUS">
            <a-form-item field="weekRange" label="Date Range">
              <a-range-picker v-model="form.weekRange" style="width:100%;" :placeholder="['From', 'To']"
                :format="dateFormate" />
            </a-form-item>
          </a-config-provider>
        </a-col>
        <a-col :span="8" class="operate">
          <a-space>
            <a-form-item label="btn">
              <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset"
                cancel-text="Cancel" @ok="confirmReset" class="warnPop">
                <a-button shape="round">Reset</a-button>
              </a-popconfirm>
            </a-form-item>
            <a-form-item label="btn">
              <a-button type="primary" shape="round" @click="search">Search</a-button>
            </a-form-item>
          </a-space>
        </a-col>
      </a-row>
    </a-form>
  </a-card>
  <a-config-provider :locale="enUS">
    <a-table :columns="columns" :data="listData.value" :pagination="false" style="margin:24px;"
      :scroll="{ y: tableHeight.value }" :loading="loading">
      <template #empty>
        <div v-if="listData.value.length === 0 && loading === false" :style="{ height: tableHeight.value - 30 + 'px' }"
          class="empty-cont">
          <img src="../../assets/images/empty/listempty.svg" alt="">
          <div>No results found</div>
        </div>
        <div v-if="loading === true" :style="{ height: tableHeight.value - 30 + 'px' }" class="skeleton-box">
        </div>
      </template>
      <template #adjustid="{ record }">
        <a-space>
          {{ record.adjust_id }}
          <icon-copy class="table-copy" @click="copyVal(record.adjust_id)" />
        </a-space>
      </template>
      <template #amount="{ record }">
        ${{ toThousands(record.amount) }}
      </template>
      <template #action="{ record }">
        <a-space>
          <a-tooltip content="Download">
            <a-button shape="circle">
              <a style="text-decoration:none;" :href="record.adjust_file_name + '?' + Math.random()"
                :download="'statement_' + record.adjust_id">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.5 5.57816V0.25H5.5V5.5787L6.91448 4.16422L7.62159 4.87132L5.00027 7.49264L2.37895 4.87132L3.08605 4.16422L4.5 5.57816ZM1.5 8.75V7.75H0.5V9.75H9.5V7.75H8.5V8.75H1.5Z"
                    fill="#4E5969" />
                </svg>
              </a>
            </a-button>
          </a-tooltip>
        </a-space>
      </template>
    </a-table>
  </a-config-provider>
  <div class="page">
    <a-space>
      <span>Total : {{ totalNum }} items</span>
      <a-button :disabled="currentpage == 1" @click="clickMinus" type="text">
        <icon-left />
      </a-button>
      <span class="currentpage">{{ currentpage }}</span>
      <div>/</div>
      <div>{{ totalPages }}</div>
      <a-button :disabled="currentpage == totalPages || totalPages == 0" @click="clickAdd" type="text">
        <icon-right />
      </a-button>
      <a-select :style="{ width: '134px' }" v-model="pageSize" @change="selectPage">
        <a-option :value="20">20 / Page</a-option>
        <a-option :value="50">50 / Page</a-option>
        <a-option :value="100">100 / Page</a-option>
        <a-option :value="200">200 / Page</a-option>
      </a-select>
    </a-space>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { Message, Notification } from '@arco-design/web-vue'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import { IconRight, IconLeft, IconCopy } from '@arco-design/web-vue/es/icon'
import { statementAdjustbills } from '@/api/statement.js'
import { toThousands } from '@/utils/handleNumber.js'
import local from '@/utils/local'
const loading = ref(false)
const route = useRoute()
const form = reactive({
  searchVal: '',
  weekRange: ['', '']
})
const startTime = ref(route.query.start)
const endTime = ref(route.query.end)
// console.log(startTime.value, endTime.value)
if (startTime.value || endTime.value) {
  form.weekRange[0] = startTime.value
  form.weekRange[1] = endTime.value
}
onMounted(() => {
  // 修改全局滚动条
  const mainCont = document.querySelector('.main-cont')
  mainCont.style = `
   overflow-y:hidden;
  `
})
onBeforeRouteLeave(() => {
  // 离开滚动条该回去
  const mainCont = document.querySelector('.main-cont')
  mainCont.style = `
   overflow-y:auto;
  `
})
// 获取窗口的高度
const tableHeight = reactive({ value: 0 })
tableHeight.value = document.body.clientHeight - 408
onMounted(() => {
  // 改变窗口高度
  window.onresize = () => {
    tableHeight.value = document.body.clientHeight - 408
  }
})
const columns = reactive([
  {
    title: 'Adjust ID',
    dataIndex: 'adjust_id',
    slotName: 'adjustid',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Adjust Date',
    dataIndex: 'adjust_date',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    slotName: 'amount',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Action',
    dataIndex: 'action',
    slotName: 'action',
    width: 100
  }
])
// 表格数据
let listData = reactive({ value: [] })
// 当前页
const currentpage = ref(1)
// 总页数
const totalPages = ref(0)
const pageSize = ref(20)
// 总数据
const totalNum = ref(0)
const getList = async () => {
  loading.value = true
  const msg = await statementAdjustbills({
    start: form.weekRange[0],
    end: form.weekRange[1],
    keyword: form.searchVal,
    pageCount: pageSize.value,
    page: currentpage.value
  })
  // console.log(msg)
  if (msg.code === 0) {
    loading.value = false
    currentpage.value = msg.data.currentPage
    totalNum.value = msg.data.totalCount
    totalPages.value = msg.data.totalPages
    listData.value = msg.data.data
  } else {
    loading.value = false
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
getList()
// 点击页数减
const clickMinus = () => {
  currentpage.value--
  getList()
}
// 点击页数加
const clickAdd = () => {
  currentpage.value++
  getList()
}
const selectPage = (val) => {
  pageSize.value = val
  getList()
}
const confirmReset = () => {
  if (form.searchVal === '' && form.weekRange[0] === '' && form.weekRange[0] === '') {
    Message.warning('No input content.')
  } else {
    form.searchVal = ''
    form.weekRange = ['', '']
    currentpage.value = 1
    getList()
  }
}
const search = () => {
  // console.log(222)
  if (form.searchVal === '' && form.weekRange[0] === '' && form.weekRange[0] === '') {
    Message.warning('No input content.')
  } else {
    currentpage.value = 1
    getList()
  }
}
// 点击复制
const copyVal = (val) => {
  const input = document.createElement("input")
  document.body.appendChild(input)
  input.setAttribute("value", val)
  input.select()
  if (document.execCommand("copy")) {
    document.execCommand("copy")
    Message.success(val + '  ' + 'copied.')
  }
  document.body.removeChild(input)
}

// 时间设置--------------------------------------------------
let dateFormate = ref('MM/DD/YYYY')
let timeFormate = JSON.parse(local.get('userInfo')).date_format
// console.log(timeFormate)
if (timeFormate === 1) {
  dateFormate.value = 'MM/DD/YYYY'
} else if (timeFormate === 2) {
  dateFormate.value = 'DD/MM/YYYY'
} else if (timeFormate === 3) {
  dateFormate.value = 'YYYY/MM/DD'
}
</script>
<style lang="less" scoped>
.empty-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    color: rgba(28, 31, 35, 0.8);
    font-weight: 400;
    font-size: 14px;
    padding-top: 30px;
  }
}

.table-copy {
  color: var(--color-fill-4);
}

.statements-title {
  font-weight: 500;
  font-size: 20px;
  color: var(--color-text-1);
  padding: 18px 24px 18px 48px;
}

.statements-list {
  margin: 0 24px 24px 24px;
}

.operate {
  :deep(.arco-form-item-label-col>.arco-form-item-label) {
    color: transparent;
  }
}

.page {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 100px;
  margin-right: 20px;
  margin-top: 24px;

  .arco-btn-text.arco-btn-disabled,
  .arco-btn-text[type='button'].arco-btn-disabled {
    color: var(--color-text-4);
  }

  .arco-btn-text,
  .arco-btn-text[type='button'] {
    color: var(--color-text-2);
  }
}

.currentpage {
  color: rgb(var(--primary-6));
  background-color: var(--color-primary-light-1);
  padding: 1px 8px;
  display: inline-block;
  box-sizing: border-box;
}

.list-til {
  font-weight: 500;
  font-size: 16px;
  color: var(--color-text-1);
}
</style>
<style>
.warnPop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--warning-6));
  background-color: rgb(var(--warning-1));
}
</style>
